import React from "react";

import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Container } from "../components";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  fourohfourNumber: {
    fontFamily: theme.typography.headings.fontFamily,
    fontSize: 200,
    fontWeight: 700,
    letterSpacing: 6,
    color: grey["300"],
    lineHeight: 1,
  },
  fourohfourContainer: {
    textAlign: "center",
    paddingTop: 40,
    paddingBottom: 40,
  },
}));

function FourOhFour() {
  const classes = useStyles();
  return (
    <Container>
      <Box my={4} className={classes.fourohfourContainer}>
        <Typography className={classes.fourohfourNumber}>404</Typography>
        <Typography variant="h4" gutterBottom>
          Nothing Found
        </Typography>
        <Typography>Sorry this page doesn't exist</Typography>
      </Box>
    </Container>
  );
}

export default FourOhFour;
